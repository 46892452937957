import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

export const data = [
    {id: 1, link: 'https://www.instagram.com/scriptninjax/', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com/MattheosT', icon: <AiOutlineTwitter/>},
    {id: 3, link: 'https://www.linkedin.com/in/mattheos-tasios-794b78190/', icon: <AiOutlineLinkedin/>},
    {id: 4, link: 'https://github.com/Matthaios9', icon: <AiFillGithub/>}
]

export default data