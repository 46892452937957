import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpg'
import Avatar3 from '../../assets/avatar3.jpg'
import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.jpg'
import Avatar6 from '../../assets/avatar6.jpg'
import Avatar7 from '../../assets/avatar7.jpg'
import Avatrar8 from '../../assets/fake1.jpg'
import Avatrar9 from '../../assets/cat.jpg'
import Mark from '../../assets/mark.jpg'
import Logan from '../../assets/logan.jpg'
import Marie from '../../assets/marie.jpg'


const data = [
        {
        id: 1,
        quote: "Mattheos brought our vision to life with their expertise in JavaScript and React.js. Their unwavering commitment to quality coding and seamless integration was the key to making our project a resounding success",
        avatar: Avatar1,
        name: 'Nana Ama McBrown',
        profession: 'Actress'
        },
        {
        id: 2,
        quote: "Impressed by Mattheos ability to blend Java and JavaScript seamlessly. Their work exceeded our expectations and created an engaging user experience.",
        avatar: Avatar2,
        name: 'Ernest Achiever',
        profession: 'Web Developer'
        },
        {
        id: 3,
        quote: "Mattheos skillfully translated our vision into a tangible product using their deep expertise in JavaScript and React.js. Their commitment to impeccable coding and smooth integration was instrumental in propelling our project to great success.",
        avatar: Avatar3,
        name: 'Daniel Vinyo',
        profession: 'Graphic Designer'
        },
        {
        id: 4,
        quote: "We entrusted Mattheos with our app development, and the results were phenomenal. Their Java and JavaScript skills brought life to our ideas, and their professionalism made the process smooth.",
        avatar: Avatar4,
        name: 'Diana Ayi',
        profession: 'Singer'
        },
        {
        id: 5,
        quote: "Mattheos is a Java and JavaScript virtuoso. Their work on our platform showcased their technical finesse and innovative approach. A true asset to any project.",
        avatar: Avatar5,
        name: 'Vivian Mensah',
        profession: 'Street Photographer'
        },
        {
        id: 6,
        quote: "Our project came to life thanks to their mastery of Java and JavaScript. Their coding expertise and commitment to quality truly shine through.",
        avatar: Avatar6,
        name: 'Hajia Bintu',
        profession: 'Brand Influencer'
        },
        {
        id: 7,
        quote: "Mattheos ability to blend Java and JavaScript seamlessly created a user experience that exceeded our expectations. A talented developer indeed.",
        avatar: Avatar7,
        name: 'Nicki Nyarko',
        profession: 'Makeup Artist'
        },
        {
            id: 8,
            quote: "Impressed by Mattheos Java and JavaScript skills. Their work transformed our ideas into a dynamic reality that resonated with our audience.",
            avatar: Avatrar8,
            name: 'Leonard Freeman',
            profession: 'Designer'
        },
        {
            id: 9,
            quote: "With their Java and JavaScript prowess, our project's technical foundation and frontend interactivity were flawlessly executed.",
            avatar: Avatrar9,
            name: 'Charlotte Owens ',
            profession: 'Business Analyst'
        },
        {
            id: 10,
            quote: "Mattheos played a pivotal role in our React and Firebase project. Their proficiency in these technologies elevated our project to new heights. Their expertise ensured robust functionality, seamless integration, and a truly impressive final product.",
            avatar: Mark,
            name: 'Mark Rodriquez ',
            profession: 'Digital Marketing'
        },
        {
            id: 11,
            quote: "Mattheos' mastery of Java and JavaScript injected a breath of innovation into our project. Their solutions not only stand strong but also manage to captivate and inspire.",
            avatar: Logan,
            name: 'Logan Beck ',
            profession: 'Management Consultant'
        },
        {
            id: 10,
            quote: "Incorporating JavaScript and React.js, Mattheos brought a perfect blend of technology to our project. Their work transformed our app, making it not only functional but also highly engaging..",
            avatar: Marie,
            name: 'Marie Collins ',
            profession: 'Market Research Analyst'
        }
        
    ]



    export default data