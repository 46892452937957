import Image1 from '../../assets/movie_explorer.png'
import Image2 from '../../assets/hotel_nirvana.png'
import Whastapp from '../../assets/whatsapp_clone.png'
import PigGame from '../../assets/pig-game.png'
import ReactMeals from '../../assets/react-meals.png'
import CoffeeWebsite from '../../assets/Cofee-website.png'
import TravelWebsite from '../../assets/Travel-website.png'
import Iphone14 from '../../assets/Iphone.png'
import OneSlice from '../../assets/one-slice.png'
import InteractiveCard from '../../assets/Interactive-rating-card.png'
import Discover from '../../assets/discover.png'
import Nexter from '../../assets/nexter.png'
import Natours from '../../assets/natours.png'
import GuessNumber from '../../assets/guess-number.png'
import Calculator from '../../assets/calculator.png'

const data = [
    {
        id: 1,
        category: 'Frontend',
        image: Image1,
        title: "Movie Explorer",
        desc: "Movie Explorer is a React.js-based project that offers users an intuitive platform to discover popular movies effortlessly. It facilitates quick searches for your favorite films and presents comprehensive movie information, including titles, release dates, and synopses. Movie Explorer streamlines the process, making movie exploration a breeze.",
        demo: 'https://movie-explorer51.netlify.app/',
        github: 'https://github.com/Matthaios9/movie_explorer'
    },
    {
        id: 2,
        category: 'Fullstack',
        image: Image2,
        title: "Hotel Nirvana",
        desc: "Hotel Nirvana is a cutting-edge hotel management system meticulously crafted using React.js and seamlessly integrated with Firebase. This robust platform empowers hoteliers to efficiently manage their properties, reservations, and guest interactions. With its user-friendly interface and real-time data synchronization, Hotel Nirvana elevates the hotel management experience to new heights, providing a tranquil and seamless solution for the modern hospitality industry..",
        demo: 'https://hotel-nirvana2.netlify.app/',
        github: 'https://github.com/Matthaios9/hotel_nirvana'
    },
    {
        id: 3,
        category: 'Fullstack',
        image: Whastapp,
        title:"Whatsapp",
        desc: "WhatsApp Clone with Chat Rooms is a messaging app reminiscent of WhatsApp. It emphasizes text-based communication and introduces chat rooms for group discussions. Users can enjoy one-on-one and group chats, creating and joining chat rooms for diverse conversations.",
        demo: 'https://whats-up-app.netlify.app',
        github: 'https://github.com/Matthaios9/whatsapp'
    },
    {
        id: 4,
        category: 'Frontend',
        image: PigGame,
        title: 'Pig-Dice game',
        desc: "Behold the HTML, CSS, and JavaScript-powered Pig Dice Roll Game! Two players take turns rolling a virtual dice on a beautifully designed interface. With each roll, points accumulate, but beware the dreaded 1 – it resets your turn's score! Victory awaits the player who first reaches a total score of 100. Strategy and risk management are the keys to success. Roll the dice, hold your breath, and strive to be the first to hit that winning score!",
        demo: 'https://zutet.com/pig-game/',
        github: 'https://github.com/Matthaios9/pig-game'
    },
    {
        id: 5,
        category: 'Frontend',
        image: ReactMeals,
        title: "React Meals",
        desc: "React Meals is a charming small restaurant website crafted with React.js. Explore our delightful menu offerings, browse through mouthwatering food photos, and find our location easily. We've designed this website to provide a seamless and user-friendly experience for our valued customers. Discover your next culinary adventure with React Meals.",
        demo: 'https://restaurant69.netlify.app/',
        github: 'https://github.com/Matthaios9/restaurant-app/'
    },
    {
        id: 6,
        category: 'Frontend',
        image: CoffeeWebsite,
        title: "Coffee Website",
        desc: "Designed with love using HTML and CSS, is a coffee enthusiast's dream. Dive into the world of aromatic brews, tantalizing blends, and cozy coffee shops. Explore our menu, learn about our story, and find your nearest café location. We've brewed this website to perfection, just like our coffee. Come and take a sip",
        demo: 'https://zutet.com/coffee-website/',
        github: 'https://github.com/Matthaios9/Coffee-Website-Main'
    },
    {
        id: 7,
        category: 'Frontend',
        image: TravelWebsite,
        title: "Travel Website",
        desc: "Meticulously crafted using HTML and CSS, is your companion for discovering the world's most stunning destinations. Dive into a treasure trove of travel inspiration, browse travel packages, and read travel tips from seasoned adventurers. We've designed this website to spark your wanderlust and help you plan your next adventure. Get ready to embark on a virtual journey with ExploreWorld!",
        demo: 'https://zutet.com/travel-website/',
        github: 'https://github.com/Matthaios9/Travel-website'
    },
    {
        id: 8,
        category: 'Frontend',
        image: Iphone14,
        title: "Iphone 14 Animation",
        desc: "A website that goes beyond the ordinary! As you scroll down, witness the magic unfold – the all-new iPhone 14 comes to life with a mesmerizing 3D rotation animation. Crafted with the latest web technologies, our website blends the elegance of HTML and CSS with the power of JavaScript. Immerse yourself in the future of smartphones as you explore every angle of the iPhone 14 in this captivating and interactive experience. Scroll to discover the future, one pixel at a time.",
        demo: 'https://iphone77.netlify.app/',
        github: 'https://github.com/Matthaios9/iphone'
    },
    {
        id: 9,
        category: 'Frontend',
        image: OneSlice,
        title: "One Slice pizza",
        desc: "website, powered by React.js, brings pizza ordering to a whole new level of convenience. Customize your pizza with your favorite toppings, choose from our mouthwatering menu, and book your slice with just a few clicks. We've designed this website for pizza lovers, making it easy to satisfy your cravings. Get ready to indulge in a delightful pizza adventure with One Slice Pizza.",
        demo: 'https://zutet.com/one-slice-pizza/',
        // github: 'https://github.com/egattor'
    },
    {
        id: 10,
        category: 'Frontend',
        image: InteractiveCard,
        title: "Interactive Card",
        desc: "Discover our beautifully crafted Rating Card UI component, meticulously built with HTML, CSS, and JavaScript. This dynamic interface allows users to rate and review with ease. Hover over the stars to see them light up and provide instant feedback. With a sleek design and seamless interaction, it's the perfect addition to enhance user engagement on your website or application. Create memorable user experiences with our Interactive Rating Card UI component today!",
        demo: 'https://zutet.com/interactive-rating-card/',
        github: 'https://github.com/Matthaios9/interactive_rating_card'
    },
    {
        id: 11,
        category: 'Frontend',
        image: Discover,
        title: "Discover Website",
        desc: "Discover makes your travel planning a breeze. Whether you're seeking luxury hotels, cozy inns, or budget-friendly flights, we've got you covered. Embark on your next journey with confidence and convenience, right here on Discover. Start your travel adventure today!.",
        demo: 'https://zutet.com/discover/#',
        github: 'https://github.com/Matthaios9/trillo'
    },
    {
        id: 12,
        category: 'Frontend',
        image: Nexter,
        title: "Nexter",
        desc: "Discover beautiful homes with Nexter, where your dream property is just a click away. Our landing page, designed using HTML and CSS, simplifies the search for your perfect home. Explore stunning listings, browse through captivating images, and embark on your homebuying journey today!",
        demo: 'https://zutet.com/nexter/',
        github: 'https://github.com/Matthaios9/nexter'
    },
    {
        id: 13,
        category: 'Frontend',
        image: Natours,
        title: "Natours:",
        desc: "Discover pristine landscapes, thrilling adventures, and serene getaways with Natours. Browse our curated listings, get inspired by stunning imagery, and embark on a journey that reconnects you with nature's beauty. Your next eco-adventure begins here. Start your exploration with Natours today!",
        demo: 'https://zutet.com/natours/',
        github: 'https://github.com/Matthaios9/natours'
    },
    {
        id: 14,
        category: 'Frontend',
        image: GuessNumber,
        title: "Guess Number:",
        desc: "Fun and challenging game built with HTML and JavaScript. Can you guess the secret number? Put your skills to the test as you input your best guesses and receive feedback in real-time. With each attempt, you'll get closer to the correct number. Keep playing, keep guessing, and see how quickly you can crack the code. Challenge yourself with 'Guess the Number' today!",
        demo: 'https://zutet.com/guess-number/',
        github: 'https://github.com/Matthaios9/guessNumber'
    },
    {
        id: 15,
        category: 'Frontend',
        image: Calculator,
        title: "Calculator:",
        desc: "Meet QuickCalc, a minimalist online calculator crafted with HTML, CSS, and JavaScript. Perform basic arithmetic and calculations effortlessly with this user-friendly tool. It's the perfect companion for quick math tasks. Try QuickCalc now!",
        demo: 'https://zutet.com/calculator/',
        github: 'https://github.com/Matthaios9/calculator'
    }
]


export default data