const data = [
    {
      id: 1,
      question: "What services do you offer as a Java and JavaScript developer?",
      answer: "As a Java and JavaScript developer, I specialize in crafting robust backend systems with Java and creating engaging frontend interfaces using JavaScript. I offer custom software development, web application design, mobile app development, and consultation services to help businesses create innovative digital solutions."
    },
    {
      id: 2,
      question: "Why should I hire a Java and JavaScript developer for my project?",
      answer: "Hiring a Java and JavaScript developer brings a versatile skill set to your project. With expertise in both backend and frontend technologies, I can create seamless, end-to-end solutions that ensure consistent user experiences and efficient functionality."
    },
    {
      id: 5,
      question: "Can you explain the importance of Java and JavaScript in modern business applications?",
      answer: "Java and JavaScript play crucial roles in modern business applications. Java's scalability and reliability make it ideal for building enterprise-level backend systems, while JavaScript's interactivity enhances user engagement and creates dynamic user interfaces, improving user satisfaction."
    },
    {
      id: 4,
      question: "How do you ensure the security of the applications you develop?",
      answer: "Security is paramount in application development. I implement best practices such as secure authentication, data encryption, and regular security audits to protect sensitive information and ensure that applications are resilient against cyber threats."
    },
    {
      id: 3,
      question: "Can you handle both small-scale projects and large-scale enterprise solutions?",
      answer: "Absolutely. My expertise spans projects of varying scales. Whether it's a small business website or a complex enterprise application, I have the knowledge and experience to tailor solutions that meet your specific needs and requirements."
    },
    {
      id: 6,
      question: "What is your approach to collaboration with clients?",
      answer: "Collaboration is at the heart of what I do. I start by understanding your business goals and requirements. Regular communication, feedback loops, and transparent project management ensure that your vision is realized throughout the development process."
    },
    {
      id:7,
      question: "How can I get started working with you on my project?",
      answer: "Getting started is easy. Simply reach out to me through the contact section to discuss your project requirements and goals. We can schedule a consultation to explore how I can best contribute to your project's success."
    }
  ]

  export default data