import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'


const data = [
    // {
    //     id: 1, icon: <SiAdobexd/>, title: 'UI/UX Design', desc: "My designs are modern and intuitive. I use industry-standard rules to make sure your users have fun using your product."
    // },
    {
        id: 2, icon: <RiReactjsLine/>, title: 'Frontend Development', desc: "Frontend development comes alive with JavaScript and React. With these tools, I create interactive user interfaces that captivate and engage. JavaScript's versatility, combined with React modular approach, allows me to craft dynamic experiences that resonate across devices. Let's collaborate to infuse your projects with JavaScript's magic and elevate your digital presence."
    },
    {
        id: 3, icon: <FaServer/>, title: 'Backend Development', desc: "Crafting the heart of web applications, my Java backend expertise ensures seamless data management, robust APIs, and reliable performance. With Java's strength, I build digital foundations that drive applications forward, providing security and scalability that stand the test of time. Let's harness the power of Java to create web experiences that leave a lasting impact."
    },
    // {
    //     id: 4, icon: <AiFillAppstore/>, title: 'App Development', desc: 'Unlike other developers in the market, I will building an app that runs on both IOS and Android devices without any extra cost to you.'
    // }
]


export default data